import React, { useState, useEffect } from "react";
import { getLogs, getLogsStats } from "../utils/api";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { FaFilter, FaTimes } from "react-icons/fa";

const StatusBadge = ({ statusCode }) => {
  const getStatusColor = () => {
    if (!statusCode) return "bg-gray-100 text-gray-800";
    if (statusCode < 200) return "bg-blue-100 text-blue-800";
    if (statusCode < 300) return "bg-green-100 text-green-800";
    if (statusCode < 400) return "bg-blue-100 text-blue-800";
    if (statusCode < 500) return "bg-yellow-100 text-yellow-800";
    return "bg-red-100 text-red-800";
  };

  const getStatusText = () => {
    if (!statusCode) return "Sem status";
    if (statusCode < 200) return "Informativo";
    if (statusCode < 300) return "Sucesso";
    if (statusCode < 400) return "Redirecionamento";
    if (statusCode < 500) return "Erro do Cliente";
    return "Erro do Servidor";
  };

  return (
    <span className={`text-xs px-2 py-1 rounded-full ${getStatusColor()}`}>
      {statusCode ? `${statusCode} - ${getStatusText()}` : getStatusText()}
    </span>
  );
};

const LogItem = ({ log }) => {
  const getBorderColor = () => {
    switch (log.level) {
      case "error":
        return "border-l-4 border-l-red-500";
      case "warn":
        return "border-l-4 border-l-yellow-500";
      default:
        return "border-l-4 border-l-blue-500";
    }
  };

  const getRequestPath = () => {
    if (log.req?.url) {
      return log.req.url;
    }
    if (log.request?.url) {
      return log.request.url;
    }
    const urlMatch = log.msg?.match(/(?:GET|POST|PUT|DELETE|PATCH)\s+([^\s]+)/);
    if (urlMatch) {
      return urlMatch[1];
    }
    const urlInMsg = log.msg?.match(/\/api\/v1\/[^\s\)"]+/);
    if (urlInMsg) {
      return urlInMsg[0];
    }
    const pathMatch = log.msg?.match(/\/[a-zA-Z0-9\/-]+/);
    if (pathMatch) {
      return pathMatch[0];
    }
    return "Sem path identificado";
  };

  const requestPath = getRequestPath();

  return (
    <div
      className={`bg-white rounded-lg shadow-sm mb-3 overflow-hidden ${getBorderColor()}`}
    >
      <div className="p-4">
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center gap-2">
            <span className="text-xs text-gray-500">
              {dayjs(log.timestamp).format("DD/MM/YYYY HH:mm:ss")}
            </span>
            {log.reqId && (
              <span className="text-xs text-gray-400">ID: {log.reqId}</span>
            )}
          </div>
          <span
            className={`text-xs px-2 py-1 rounded-full ${
              log.level === "error"
                ? "bg-red-100 text-red-800"
                : log.level === "warn"
                ? "bg-yellow-100 text-yellow-800"
                : "bg-blue-100 text-blue-800"
            }`}
          >
            {log.level}
          </span>
        </div>

        <div className="text-sm text-gray-700">
          <div className="mb-2 flex items-center">
            <span
              className={`text-xs px-2 py-1 rounded-full ${
                requestPath === "Sem path identificado"
                  ? "bg-gray-100 text-gray-500"
                  : "bg-blue-50 text-blue-700"
              }`}
            >
              Path: {requestPath}
            </span>
          </div>
          <p>{log.msg}</p>
          {(log.res || log.responseTime > 0) && (
            <div className="flex items-center gap-2 mt-2">
              {log.res && <StatusBadge statusCode={log.res.statusCode} />}
              {log.responseTime > 0 && (
                <span
                  className={`text-xs px-2 py-1 rounded-full ${
                    log.responseTime > 1000
                      ? "bg-red-100 text-red-800"
                      : log.responseTime > 500
                      ? "bg-yellow-100 text-yellow-800"
                      : "bg-green-100 text-green-800"
                  }`}
                >
                  {Math.round(log.responseTime)}ms
                </span>
              )}
            </div>
          )}
        </div>

        <div className="mt-2 text-xs text-gray-500">
          {log.hostname && <p>Host: {log.hostname}</p>}
          {log.pid && <p>PID: {log.pid}</p>}
        </div>
      </div>
    </div>
  );
};

const StatsCard = ({ title, value }) => (
  <div className="bg-white rounded-lg shadow-sm p-4">
    <p className="text-sm text-gray-500">{title}</p>
    <p className="text-xl font-medium mt-1">{value}</p>
  </div>
);

const SystemLogs = () => {
  const navigate = useNavigate();
  const [logs, setLogs] = useState([]);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(null);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [filters, setFilters] = useState({
    level: "",
    search: "",
    method: "",
    statusCode: "",
    startDate: "",
    endDate: "",
    ip: "",
    url: "",
    minResponseTime: "",
    maxResponseTime: "",
  });
  const [pagination, setPagination] = useState({
    total: 0,
    totalPages: 0,
  });

  const checkAdminPermission = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return false;
    }

    try {
      const payload = JSON.parse(atob(token.split(".")[1]));
      if (!payload.scopes || !payload.scopes.includes("admin")) {
        navigate("/denied");
        return false;
      }
      return true;
    } catch (err) {
      console.error("Erro ao decodificar token:", err);
      navigate("/login");
      return false;
    }
  };

  useEffect(() => {
    const hasPermission = checkAdminPermission();
    if (!hasPermission) return;

    if (page === null) {
      getLogs({ page: 1, ...filters })
        .then((response) => {
          if (response.success === false) throw new Error(response.error);
          setPage(response.pagination.totalPages || 1);
          setPagination(response.pagination);
        })
        .catch((err) => {
          console.error("Erro ao obter total de páginas:", err);
          setPage(1);
        });
    } else {
      const fetchData = async () => {
        await Promise.all([fetchLogs(), fetchStats()]);
      };
      fetchData();
    }
  }, [page, filters]);

  const fetchLogs = async () => {
    setLoading(true);
    try {
      const response = await getLogs({ page, ...filters });
      if (response.success === false) throw new Error(response.error);

      const filteredLogs = response.data
        .filter((log) => {
          const hasLogsRoute = [
            log.req?.url,
            log.request?.url,
            log.msg,
            log.path,
            log.url,
          ].some((field) => field && field.toString().includes("/api/v1/logs"));

          return !hasLogsRoute;
        })
        .sort((a, b) => {
          const timeA = new Date(a.timestamp).getTime();
          const timeB = new Date(b.timestamp).getTime();
          return timeB - timeA;
        });

      setLogs(filteredLogs);
      setPagination(response.pagination);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  const fetchStats = async () => {
    try {
      const response = await getLogsStats();
      if (response.success === false) throw new Error(response.error);

      setStats({
        totalRequests: response.totalRequests || 0,
        avgResponseTime: response.avgResponseTime || 0,
        totalValidResponses: response.totalValidResponses || 0,
        errorCount: response.errorCount || 0,
      });
    } catch (err) {
      console.error("Erro ao carregar estatísticas:", err);
    }
  };

  const handleFilterChange = (field) => (event) => {
    setFilters((prev) => ({ ...prev, [field]: event.target.value }));
    setPage(1);
  };

  const clearFilters = () => {
    setFilters({
      level: "",
      search: "",
      method: "",
      statusCode: "",
      startDate: "",
      endDate: "",
      ip: "",
      url: "",
      minResponseTime: "",
      maxResponseTime: "",
    });
    setPage(1);
  };

  const handleQuickFilter = (type) => {
    const now = dayjs();
    switch (type) {
      case "lastMinute":
        setFilters((prev) => ({
          ...prev,
          startDate: now.subtract(1, "minute").format("YYYY-MM-DDTHH:mm:ss"),
          endDate: now.format("YYYY-MM-DDTHH:mm:ss"),
        }));
        break;
      case "lastHour":
        setFilters((prev) => ({
          ...prev,
          startDate: now.subtract(1, "hour").format("YYYY-MM-DDTHH:mm:ss"),
          endDate: now.format("YYYY-MM-DDTHH:mm:ss"),
        }));
        break;
      case "errors":
        setFilters((prev) => ({
          ...prev,
          level: "error",
        }));
        break;
      case "slowResponses":
        setFilters((prev) => ({
          ...prev,
          minResponseTime: "1000",
        }));
        break;
    }
    setPage(1);
  };

  return (
    <div className="max-w-7xl mx-auto px-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-xl font-medium">Logs do Sistema</h1>
        {pagination.totalPages > 1 && page !== null && (
          <div className="flex gap-2">
            <button
              onClick={() => setPage((prev) => Math.max(1, prev - 1))}
              disabled={page === 1}
              className={`px-4 py-2 rounded-lg text-sm ${
                page === 1
                  ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                  : "bg-white hover:bg-gray-50 text-gray-700"
              }`}
            >
              Anterior
            </button>
            <span className="px-4 py-2 bg-gray-50 rounded-lg text-sm text-gray-600">
              Página {page} de {pagination.totalPages}
            </span>
            <button
              onClick={() =>
                setPage((prev) => Math.min(pagination.totalPages, prev + 1))
              }
              disabled={page === pagination.totalPages}
              className={`px-4 py-2 rounded-lg text-sm ${
                page === pagination.totalPages
                  ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                  : "bg-white hover:bg-gray-50 text-gray-700"
              }`}
            >
              Próxima
            </button>
          </div>
        )}
      </div>

      {stats && (
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-6">
          <StatsCard
            title="Total de Requisições"
            value={stats.totalRequests.toLocaleString()}
          />
          <StatsCard
            title="Tempo Médio de Resposta"
            value={
              stats.avgResponseTime > 0
                ? `${Math.round(
                    stats.avgResponseTime
                  )}ms (${stats.totalValidResponses.toLocaleString()} reqs)`
                : `0ms (${stats.totalValidResponses.toLocaleString()} reqs)`
            }
          />
          <StatsCard
            title="Total de Erros"
            value={stats.errorCount.toLocaleString()}
          />
        </div>
      )}

      <div className="bg-white rounded-lg shadow-sm p-4 mb-6">
        <div className="flex justify-between items-center mb-4">
          <div className="flex gap-2">
            <button
              onClick={() => handleQuickFilter("lastMinute")}
              className="px-3 py-1 text-xs rounded-full bg-blue-100 text-blue-800 hover:bg-blue-200"
            >
              Último Minuto
            </button>
            <button
              onClick={() => handleQuickFilter("lastHour")}
              className="px-3 py-1 text-xs rounded-full bg-blue-100 text-blue-800 hover:bg-blue-200"
            >
              Última Hora
            </button>
            <button
              onClick={() => handleQuickFilter("errors")}
              className="px-3 py-1 text-xs rounded-full bg-red-100 text-red-800 hover:bg-red-200"
            >
              Erros
            </button>
            <button
              onClick={() => handleQuickFilter("slowResponses")}
              className="px-3 py-1 text-xs rounded-full bg-yellow-100 text-yellow-800 hover:bg-yellow-200"
            >
              Respostas Lentas
            </button>
          </div>
          <div className="flex gap-2">
            <button
              onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
              className="flex items-center gap-2 text-sm text-gray-600 hover:text-gray-800"
            >
              <FaFilter />
              {showAdvancedFilters ? "Ocultar Filtros" : "Filtros Avançados"}
            </button>
            {Object.values(filters).some((v) => v !== "") && (
              <button
                onClick={clearFilters}
                className="flex items-center gap-2 text-sm text-red-600 hover:text-red-800"
              >
                <FaTimes />
                Limpar Filtros
              </button>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-12 gap-4">
          <div className="sm:col-span-3">
            <select
              value={filters.level}
              onChange={handleFilterChange("level")}
              className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Todos os níveis</option>
              <option value="error">Erro</option>
              <option value="warn">Aviso</option>
              <option value="info">Info</option>
            </select>
          </div>
          <div className="sm:col-span-3">
            <select
              value={filters.method}
              onChange={handleFilterChange("method")}
              className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Todos os métodos</option>
              <option value="GET">GET</option>
              <option value="POST">POST</option>
              <option value="PUT">PUT</option>
              <option value="DELETE">DELETE</option>
            </select>
          </div>
          <div className="sm:col-span-6">
            <input
              type="text"
              placeholder="Buscar..."
              value={filters.search}
              onChange={handleFilterChange("search")}
              className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>

        {showAdvancedFilters && (
          <div className="grid grid-cols-1 sm:grid-cols-12 gap-4 mt-4">
            <div className="sm:col-span-3">
              <input
                type="datetime-local"
                value={filters.startDate}
                onChange={handleFilterChange("startDate")}
                className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Data Inicial"
              />
            </div>
            <div className="sm:col-span-3">
              <input
                type="datetime-local"
                value={filters.endDate}
                onChange={handleFilterChange("endDate")}
                className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Data Final"
              />
            </div>
            <div className="sm:col-span-3">
              <input
                type="text"
                value={filters.ip}
                onChange={handleFilterChange("ip")}
                className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="IP"
              />
            </div>
            <div className="sm:col-span-3">
              <input
                type="text"
                value={filters.statusCode}
                onChange={handleFilterChange("statusCode")}
                className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Código de Status"
              />
            </div>
            <div className="sm:col-span-4">
              <input
                type="text"
                value={filters.url}
                onChange={handleFilterChange("url")}
                className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="URL"
              />
            </div>
            <div className="sm:col-span-4">
              <input
                type="number"
                value={filters.minResponseTime}
                onChange={handleFilterChange("minResponseTime")}
                className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Tempo Mín. Resposta (ms)"
              />
            </div>
            <div className="sm:col-span-4">
              <input
                type="number"
                value={filters.maxResponseTime}
                onChange={handleFilterChange("maxResponseTime")}
                className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Tempo Máx. Resposta (ms)"
              />
            </div>
          </div>
        )}
      </div>

      {error && (
        <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6 text-sm text-red-700">
          {error}
        </div>
      )}

      <div className="space-y-3">
        {loading
          ? [...Array(5)].map((_, i) => (
              <div
                key={i}
                className="animate-pulse bg-gray-100 h-24 rounded-lg"
              />
            ))
          : logs.map((log, index) => <LogItem key={index} log={log} />)}
      </div>
    </div>
  );
};

export default SystemLogs;
