// src/pages/ProjectDetailsPage.js
import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { FaGoogle, FaGlobe, FaChartLine, FaTrash } from "react-icons/fa"; // Importando FaTrash
import {
  getProjectById,
  updateGoogleAdsAccount,
  getDomainInProject,
  getManagerIdInProject,
  resetGoogleAdsFields, // Importando a nova função
} from "../utils/api";
import IntegrateAccount from "../components/googleads/IntegrateAccount";
import AddToAdManager from "../components/admanager/AddToAdManager";
import AddDomain from "../components/AddDomain";
import ProjectDetails from "../components/ProjectDetails";
import TrafficManagers from "../components/TrafficManagers";
import ProjectConfigurations from "../components/ProjectConfigurations";

import { jwtDecode } from "jwt-decode"; // Correção na importação do jwtDecode

const ProjectDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [domain, setDomain] = useState("");
  const [googleAdsAccounts, setGoogleAdsAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [managers, setManagers] = useState([]);
  const [configurations, setConfigurations] = useState({
    RevShare: "",
    Tax: "",
    DollarDepreciation: "",
  });
  const [roiTargets, setRoiTargets] = useState({
    roiGoal: "",
    roiAbove: "",
    roiBelow: "",
  });
  const [accountId, setAccountId] = useState("");
  const hasFetched = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProject = async () => {
      if (hasFetched.current) return;
      hasFetched.current = true;

      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error(
            "Token não encontrado. Por favor, faça login novamente."
          );
        }

        const decodedToken = jwtDecode(token);
        const accountId = decodedToken.id;
        setAccountId(accountId);
        if (!accountId) {
          throw new Error("ID da conta não encontrado no token.");
        }

        const projectData = await getProjectById(id);
        if (!projectData) {
          throw new Error("Projeto não encontrado.");
        }

        setProject(projectData);

        const { config } = projectData;
        if (config) {
          setConfigurations({
            RevShare: config.RevShare || "",
            Tax: config.Tax || "",
            DollarDepreciation: config.DollarDepreciation || "",
          });
          setRoiTargets({
            roiGoal: config.roiGoal || "",
            roiAbove: config.roiAbove || "",
            roiBelow: config.roiBelow || "",
          });
        }

        if (projectData.googleAdsId) {
          setGoogleAdsAccounts(projectData.googleAdsId);
          if (projectData.googleAdsId.length === 1) {
            setSelectedAccount(projectData.googleAdsId[0]);
          }
        }

        const domainData = await getDomainInProject(id, accountId);
        setDomain(domainData || "");

        const managerData = await getManagerIdInProject(id, accountId);
        if (managerData && managerData.managers) {
          setManagers(managerData.managers);
        }
      } catch (error) {
        const errorMessage =
          typeof error === "string"
            ? error
            : error.message || "Erro desconhecido";
        setError(errorMessage);
        toast.error(errorMessage);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProject();
  }, [id, navigate]);

  // Função para integrar uma conta do Google Ads
  const handleIntegrateGoogleAds = async () => {
    try {
      const response = await updateGoogleAdsAccount(id, selectedAccount);

      // Verifica se a resposta da API tem status 200
      if (response && response.googleAdsId) {
        setProject(response); // Atualiza o estado completo do projeto
        setGoogleAdsAccounts(response.googleAdsId);
        toast.success("Conta do Google Ads integrada com sucesso!");
      } else {
        throw new Error("Erro ao integrar conta do Google Ads.");
      }
    } catch (error) {
      toast.error("Erro ao integrar conta do Google Ads: " + error.message);
    }
  };

  // Função para resetar/desvincular todas as contas do Google Ads
  const handleResetGoogleAds = async () => {
    if (
      !window.confirm(
        "Tem certeza que deseja desvincular todas as contas do Google Ads deste projeto?"
      )
    ) {
      return;
    }

    try {
      const result = await resetGoogleAdsFields(id);

      // Mantém a lógica original que verifica o atributo 'success'
      if (result.success) {
        setGoogleAdsAccounts([]);
        setSelectedAccount("");
        toast.success("Contas do Google Ads foram resetadas com sucesso!");
      } else {
        throw new Error(
          result.error || "Erro ao resetar contas do Google Ads."
        );
      }
    } catch (error) {
      toast.error("Erro ao resetar contas do Google Ads: " + error.message);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-gray-600">Carregando...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-4xl mx-auto">
        {/* Detalhes do Projeto */}
        <ProjectDetails project={project} />

        {/* Componentes de Integração */}
        <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
          {/* Domínio */}
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200 flex items-center">
            <FaGlobe className="text-blue-500 mr-2" />
            <div>
              <h3 className="text-lg font-semibold mb-2">Domínio</h3>
              <AddDomain
                projectId={project.id}
                existingDomain={domain}
                onDomainUpdated={(newDomain) => setDomain(newDomain)}
              />
            </div>
          </div>

          {/* Google Ads */}
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200 flex flex-col">
            <div className="flex items-center mb-2">
              <FaGoogle className="text-green-500 mr-2" />
              <h3 className="text-lg font-semibold">Google Ads Integrado</h3>
            </div>
            <p className="text-gray-700 mb-2">
              {googleAdsAccounts.length
                ? googleAdsAccounts.join(", ")
                : "Nenhuma conta vinculada"}
            </p>
            {!googleAdsAccounts.length && (
              <IntegrateAccount projectId={project.id} />
            )}
            {googleAdsAccounts.length > 0 && (
              <div className="flex flex-col">
                {googleAdsAccounts.length > 1 && (
                  <div className="mb-4">
                    <label className="text-gray-600 mb-1 block">
                      Selecione a Conta Google Ads:
                    </label>
                    <select
                      value={selectedAccount}
                      onChange={(e) => setSelectedAccount(e.target.value)}
                      onBlur={handleIntegrateGoogleAds}
                      className="border border-gray-300 p-2 rounded-lg w-full focus:ring focus:ring-blue-300 text-sm"
                    >
                      <option value="">Selecione uma conta</option>
                      {googleAdsAccounts.map((account) => (
                        <option key={account} value={account}>
                          {account}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {/* Botão de Resetar/Desvincular Contas do Google Ads */}
                <button
                  onClick={handleResetGoogleAds}
                  className="flex items-center text-gray-500 hover:text-gray-700 focus:outline-none text-sm mt-2"
                  title="Desvincular contas do Google Ads"
                  aria-label="Desvincular contas do Google Ads"
                >
                  <FaTrash className="mr-1" />
                  Desvincular Contas
                </button>
              </div>
            )}
          </div>

          {/* Google Ad Manager */}
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200 flex items-center">
            <FaGoogle className="text-blue-500 mr-2" />
            <div>
              <h3 className="text-lg font-semibold mb-2">
                Google Ad Manager Integrado
              </h3>
              <p className="text-gray-700">
                {project.admanagerId
                  ? `Conta Ad Manager: ${project.admanagerId}`
                  : "Nenhuma conta vinculada"}
              </p>
              {!project.admanagerId && <AddToAdManager project={project} />}
            </div>
          </div>
        </div>

        {/* Gestores de Tráfego */}
        {project.accountId === accountId && (
          <TrafficManagers
            projectId={project.id}
            managers={managers}
            setManagers={setManagers}
          />
        )}

        {/* Configurações */}
        {project.accountId === accountId && (
          <ProjectConfigurations
            projectId={project.id}
            initialConfigurations={configurations}
            initialRoiTargets={roiTargets}
          />
        )}

        <div className="mt-8">
          <Link
            to={`/project/${id}/performance`}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-200 flex items-center"
          >
            <FaChartLine className="mr-2" />
            Visualizar performance do projeto
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailsPage;
