import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getPixSalesReport, getPixSalesMetrics } from "../utils/api";
import dayjs from "dayjs";
import {
  FaChartLine,
  FaMoneyBillWave,
  FaCalendarAlt,
  FaFilter,
  FaTimes,
  FaSpinner,
  FaSearch,
  FaDownload,
  FaCoins,
} from "react-icons/fa";
import toast, { Toaster } from "react-hot-toast";

const StatsCard = ({ title, value, icon: Icon, subtext }) => (
  <div className="bg-white rounded-lg shadow-sm p-4">
    <div className="flex items-center gap-2 text-gray-500 mb-2">
      <Icon className="text-gray-400" size={16} />
      <p className="text-sm">{title}</p>
    </div>
    <p className="text-xl font-medium">{value}</p>
    {subtext && <p className="text-xs text-gray-500 mt-1">{subtext}</p>}
  </div>
);

const SalesReport = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [sales, setSales] = useState([]);
  const [metrics, setMetrics] = useState(null);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    period: "today",
    date: "",
    startDate: "",
    endDate: "",
    status: "",
    minAmount: "",
    maxAmount: "",
  });
  const [pagination, setPagination] = useState({
    total: 0,
    totalPages: 0,
  });

  useEffect(() => {
    const checkPermissions = () => {
      const token = localStorage.getItem("token");
      if (token) {
        const payload = JSON.parse(atob(token.split(".")[1]));
        if (!payload.scopes || !payload.scopes.includes("admin")) {
          navigate("/denied");
          return false;
        }
        return true;
      }
      navigate("/login");
      return false;
    };

    if (checkPermissions()) {
      fetchData();
    }
  }, [page, filters]);

  const fetchData = async () => {
    setLoading(true);
    try {
      let queryParams = { ...filters };

      if (filters.startDate) {
        queryParams.startDate = dayjs(filters.startDate).format(
          "YYYY-MM-DDTHH:mm:ss.000[Z]"
        );
      }
      if (filters.endDate) {
        queryParams.endDate = dayjs(filters.endDate).format(
          "YYYY-MM-DDTHH:mm:ss.999[Z]"
        );
      }
      if (filters.date) {
        queryParams.date = dayjs(filters.date).format("YYYY-MM-DD");
      }

      const reportResponse = await getPixSalesReport({
        page,
        ...queryParams,
      });

      if (reportResponse.success === false)
        throw new Error(reportResponse.error);

      setSales(reportResponse.data.sales || []);
      setPagination(reportResponse.data.pagination);

      const metrics = reportResponse.data.metrics;
      setMetrics({
        totalSales: reportResponse.data.sales.length,
        totalAmount: metrics.totalAmount,
        averageAmount: metrics.avgTicket,
        totalCredits: metrics.totalCredits,
        approvalRate: calculateApprovalRate(metrics.statusCount),
        period: reportResponse.data.period,
      });
    } catch (error) {
      toast.error("Erro ao carregar dados");
      console.error("Erro:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleQuickFilter = (period) => {
    setFilters((prev) => ({
      ...prev,
      period,
      startDate: "",
      endDate: "",
      date: "",
    }));
    setPage(1);
  };

  const clearFilters = () => {
    setFilters({
      period: "today",
      date: "",
      startDate: "",
      endDate: "",
      status: "",
      minAmount: "",
      maxAmount: "",
    });
    setPage(1);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const calculateApprovalRate = (statusCount) => {
    const total = Object.values(statusCount).reduce(
      (acc, curr) => acc + curr,
      0
    );
    const approved = statusCount.approved || 0;
    return total > 0 ? Math.round((approved / total) * 100) : 0;
  };

  const exportToCSV = () => {
    // Verifica se há dados para exportar
    if (!sales || sales.length === 0) {
      toast.error("Não há dados para exportar");
      return;
    }

    // Define as colunas do CSV
    const headers = [
      "ID",
      "Data",
      "Valor",
      "Créditos",
      "Email",
      "Status",
      "Descrição",
    ];

    // Formata os dados para o CSV
    const csvData = sales.map((sale) => [
      sale.id,
      dayjs(sale.createdAt).format("DD/MM/YYYY HH:mm:ss"),
      sale.transactionAmount,
      sale.creditsAcquired,
      sale.payerEmail,
      sale.status === "approved"
        ? "Aprovado"
        : sale.status === "pending"
        ? "Pendente"
        : "Rejeitado",
      sale.description,
    ]);

    // Adiciona os headers ao início do array
    csvData.unshift(headers);

    // Converte para formato CSV
    const csvContent = csvData
      .map((row) =>
        row
          .map((cell) => {
            // Trata células que podem conter vírgulas ou quebras de linha
            if (cell === null || cell === undefined) return '""';
            return `"${cell.toString().replace(/"/g, '""')}"`;
          })
          .join(",")
      )
      .join("\n");

    // Cria o blob com encoding UTF-8 e BOM para suporte a caracteres especiais no Excel
    const blob = new Blob(["\uFEFF" + csvContent], {
      type: "text/csv;charset=utf-8;",
    });

    // Cria o link de download
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    // Define o nome do arquivo com timestamp
    const fileName = `vendas_${dayjs().format("YYYY-MM-DD_HH-mm")}.csv`;

    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);

    // Clica no link e remove
    link.click();
    document.body.removeChild(link);

    toast.success("Arquivo exportado com sucesso!");
  };

  return (
    <div className="max-w-7xl mx-auto px-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-xl font-medium">Relatório de Vendas PIX</h1>
        <button
          onClick={exportToCSV}
          className="bg-green-600 text-white px-4 py-2 rounded-lg text-sm hover:bg-green-700 transition-colors flex items-center gap-2"
        >
          <FaDownload size={14} />
          Exportar CSV
        </button>
      </div>

      {/* Stats Cards */}
      {metrics && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          <StatsCard
            title="Total de Vendas"
            value={metrics.totalSales}
            icon={FaChartLine}
            subtext={`Período: ${metrics.period?.name || "Hoje"}`}
          />
          <StatsCard
            title="Valor Total"
            value={formatCurrency(metrics.totalAmount)}
            icon={FaMoneyBillWave}
          />
          <StatsCard
            title="Créditos Vendidos"
            value={metrics.totalCredits}
            icon={FaCoins}
          />
          <StatsCard
            title="Ticket Médio"
            value={formatCurrency(metrics.averageAmount)}
            icon={FaMoneyBillWave}
          />
        </div>
      )}

      {/* Filters */}
      <div className="bg-white rounded-lg shadow-sm p-4 mb-6">
        <div className="flex justify-between items-center mb-4">
          <div className="flex gap-2">
            <button
              onClick={() => handleQuickFilter("today")}
              className={`px-3 py-1 text-xs rounded-full ${
                filters.period === "today"
                  ? "bg-blue-600 text-white"
                  : "bg-blue-100 text-blue-800 hover:bg-blue-200"
              }`}
            >
              Hoje
            </button>
            <button
              onClick={() => handleQuickFilter("yesterday")}
              className={`px-3 py-1 text-xs rounded-full ${
                filters.period === "yesterday"
                  ? "bg-blue-600 text-white"
                  : "bg-blue-100 text-blue-800 hover:bg-blue-200"
              }`}
            >
              Ontem
            </button>
            <button
              onClick={() => handleQuickFilter("last7days")}
              className={`px-3 py-1 text-xs rounded-full ${
                filters.period === "last7days"
                  ? "bg-blue-600 text-white"
                  : "bg-blue-100 text-blue-800 hover:bg-blue-200"
              }`}
            >
              Últimos 7 dias
            </button>
            <button
              onClick={() => handleQuickFilter("thisMonth")}
              className={`px-3 py-1 text-xs rounded-full ${
                filters.period === "thisMonth"
                  ? "bg-blue-600 text-white"
                  : "bg-blue-100 text-blue-800 hover:bg-blue-200"
              }`}
            >
              Este Mês
            </button>
          </div>
          <div className="flex gap-2">
            <button
              onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
              className="flex items-center gap-2 text-sm text-gray-600 hover:text-gray-800"
            >
              <FaFilter />
              {showAdvancedFilters ? "Ocultar Filtros" : "Filtros Avançados"}
            </button>
            {Object.values(filters).some((v) => v !== "") && (
              <button
                onClick={clearFilters}
                className="flex items-center gap-2 text-sm text-red-600 hover:text-red-800"
              >
                <FaTimes />
                Limpar Filtros
              </button>
            )}
          </div>
        </div>

        {showAdvancedFilters && (
          <div className="grid grid-cols-1 sm:grid-cols-12 gap-4 mt-4">
            {/* Data Específica */}
            <div className="sm:col-span-3">
              <label className="block text-xs font-medium text-gray-700 mb-1">
                Data Específica
              </label>
              <input
                type="date"
                value={filters.date}
                onChange={(e) =>
                  setFilters((prev) => ({
                    ...prev,
                    date: e.target.value,
                    startDate: "",
                    endDate: "",
                    period: "custom",
                  }))
                }
                className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Selecione uma data"
              />
              <p className="mt-1 text-xs text-gray-500">
                Relatório de um dia específico
              </p>
            </div>

            {/* Período Personalizado */}
            <div className="sm:col-span-6">
              <label className="block text-xs font-medium text-gray-700 mb-1">
                Período Personalizado
              </label>
              <div className="flex gap-2">
                <div className="flex-1">
                  <input
                    type="datetime-local"
                    value={filters.startDate}
                    onChange={(e) =>
                      setFilters((prev) => ({
                        ...prev,
                        startDate: e.target.value,
                        date: "",
                        period: "custom",
                      }))
                    }
                    className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Data Inicial"
                  />
                  <p className="mt-1 text-xs text-gray-500">
                    Data e hora inicial
                  </p>
                </div>
                <div className="flex-1">
                  <input
                    type="datetime-local"
                    value={filters.endDate}
                    onChange={(e) =>
                      setFilters((prev) => ({
                        ...prev,
                        endDate: e.target.value,
                        date: "",
                        period: "custom",
                      }))
                    }
                    className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Data Final"
                  />
                  <p className="mt-1 text-xs text-gray-500">
                    Data e hora final
                  </p>
                </div>
              </div>
              <p className="mt-1 text-xs text-gray-500">
                Selecione um intervalo de datas e horários específicos
              </p>
            </div>

            {/* Status e Valores */}
            <div className="sm:col-span-3">
              <label className="block text-xs font-medium text-gray-700 mb-1">
                Status da Venda
              </label>
              <select
                value={filters.status}
                onChange={(e) =>
                  setFilters((prev) => ({ ...prev, status: e.target.value }))
                }
                className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Todos os status</option>
                <option value="approved">Aprovado</option>
                <option value="pending">Pendente</option>
              </select>
            </div>

            <div className="sm:col-span-3">
              <div className="flex gap-2">
                <input
                  type="number"
                  value={filters.minAmount}
                  onChange={(e) =>
                    setFilters((prev) => ({
                      ...prev,
                      minAmount: e.target.value,
                    }))
                  }
                  className="w-1/2 px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Valor Min"
                />
                <input
                  type="number"
                  value={filters.maxAmount}
                  onChange={(e) =>
                    setFilters((prev) => ({
                      ...prev,
                      maxAmount: e.target.value,
                    }))
                  }
                  className="w-1/2 px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Valor Max"
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Sales Table */}
      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  ID
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Data
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Valor
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Créditos
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {loading ? (
                <tr>
                  <td colSpan="6" className="px-6 py-4 text-center">
                    <FaSpinner className="animate-spin inline-block" />
                  </td>
                </tr>
              ) : sales.length > 0 ? (
                sales.map((sale) => (
                  <tr key={sale.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {sale.id}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {dayjs(sale.createdAt).format("DD/MM/YYYY HH:mm")}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {formatCurrency(sale.transactionAmount)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {sale.creditsAcquired}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {sale.payerEmail}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          sale.status === "approved"
                            ? "bg-green-100 text-green-800"
                            : sale.status === "pending"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-red-100 text-red-800"
                        }`}
                      >
                        {sale.status === "approved"
                          ? "Aprovado"
                          : sale.status === "pending"
                          ? "Pendente"
                          : "Rejeitado"}
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="6"
                    className="px-6 py-4 text-center text-gray-500"
                  >
                    Nenhuma venda encontrada
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination */}
      {pagination.totalPages > 1 && (
        <div className="flex justify-center mt-6">
          <div className="flex gap-2">
            <button
              onClick={() => setPage((prev) => Math.max(1, prev - 1))}
              disabled={page === 1}
              className={`px-4 py-2 rounded-lg text-sm ${
                page === 1
                  ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                  : "bg-white hover:bg-gray-50 text-gray-700"
              }`}
            >
              Anterior
            </button>
            <span className="px-4 py-2 bg-gray-50 rounded-lg text-sm text-gray-600">
              Página {page} de {pagination.totalPages}
            </span>
            <button
              onClick={() =>
                setPage((prev) => Math.min(pagination.totalPages, prev + 1))
              }
              disabled={page === pagination.totalPages}
              className={`px-4 py-2 rounded-lg text-sm ${
                page === pagination.totalPages
                  ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                  : "bg-white hover:bg-gray-50 text-gray-700"
              }`}
            >
              Próxima
            </button>
          </div>
        </div>
      )}

      <Toaster position="top-right" />
    </div>
  );
};

export default SalesReport;
