import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { FaAd, FaCopy } from "react-icons/fa";
import {
  updateAdManagerAccount,
  addOrUpdateDomainInProject,
  getDomainInProject,
} from "../../utils/api";

const AddToAdManager = ({ project }) => {
  const [gamId, setGamId] = useState("");
  const [domain, setDomain] = useState("");
  const [isDomainSet, setIsDomainSet] = useState(false);
  const [isGamIntegrated, setIsGamIntegrated] = useState(false);
  const [integrationType, setIntegrationType] = useState(""); // "Manual" ou "Automática"

  const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,6}$/;
  const gamIdRegex = /^\d+$/;

  useEffect(() => {
    if (project && project.admanagerId) {
      setGamId(project.admanagerId);
      setIsGamIntegrated(true);
    }

    const checkDomain = async () => {
      try {
        const existingDomain = await getDomainInProject(project.id);
        if (existingDomain) {
          setDomain(existingDomain);
          setIsDomainSet(true);
        }
      } catch (error) {
        toast.error("Erro ao verificar o domínio: " + error.message);
      }
    };

    checkDomain();
  }, [project]);

  const handleSave = async () => {
    if (integrationType === "Manual") {
      if (!gamIdRegex.test(gamId)) {
        toast.error("O ID do Google Ad Manager deve conter apenas números.");
        return;
      }

      if (!domainRegex.test(domain)) {
        toast.error("Por favor, insira um domínio válido. Ex: seudominio.com");
        return;
      }

      try {
        const response = await updateAdManagerAccount(project.id, gamId);
        // Verifica se o admanagerId foi atualizado na resposta
        if (response.admanagerId === gamId) {
          const domainResponse = await addOrUpdateDomainInProject(
            project.id,
            domain
          );
          if (domainResponse.domain === domain) {
            toast.success(
              "Google Ad Manager e domínio vinculados com sucesso!"
            );
            setIsGamIntegrated(true);
          } else {
            toast.error("Erro ao vincular o domínio");
          }
        } else {
          toast.error("Erro ao vincular o Google Ad Manager");
        }
      } catch (error) {
        toast.error(
          "Erro ao vincular o Google Ad Manager: " +
            (error.message || "Erro desconhecido")
        );
      }
    } else if (integrationType === "Automática") {
      window.location.href = "/oauth/google-admanager";
    } else {
      toast.error("Por favor, selecione um tipo de integração.");
    }
  };

  const handleCopyServiceAccount = () => {
    navigator.clipboard.writeText(
      "adpilott@adpilot-423403.iam.gserviceaccount.com"
    );
    toast.success("Conta de Serviço copiada com sucesso!");
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
      <Toaster position="top-right" reverseOrder={false} />
      <h2 className="text-xl font-bold mb-4 text-gray-700 flex items-center">
        {isGamIntegrated ? (
          <>
            <FaAd className="mr-2 text-lg" />
            Google Ad Manager já integrado
          </>
        ) : (
          "Adicionar Google Ad Manager"
        )}
      </h2>

      {!isGamIntegrated && (
        <>
          <div className="mb-4">
            <label className="text-gray-600 mb-1 block">
              Escolha o tipo de integração:
            </label>
            <select
              value={integrationType}
              onChange={(e) => setIntegrationType(e.target.value)}
              className="border border-gray-300 p-2 rounded w-full focus:ring focus:ring-blue-200"
            >
              <option value="">Selecione</option>
              <option value="Manual">Manual</option>
              <option value="Automática" disabled>
                Automática
              </option>
            </select>
          </div>

          {integrationType === "Manual" && (
            <>
              <div className="mb-4">
                <label className="text-gray-600 mb-1 block">
                  Conta de Serviço:
                </label>
                <div className="flex items-center">
                  <input
                    type="text"
                    readOnly
                    value="adpilott@adpilot-423403.iam.gserviceaccount.com"
                    className="border border-gray-300 p-2 rounded w-full bg-gray-100 cursor-not-allowed"
                  />
                  <FaCopy
                    className="ml-2 text-lg text-gray-500 cursor-pointer hover:text-gray-700"
                    onClick={handleCopyServiceAccount}
                  />
                </div>
              </div>

              <div className="mb-4">
                <label className="text-gray-600 mb-1 block">
                  Insira o ID do Google Ad Manager:
                </label>
                <input
                  type="text"
                  value={gamId}
                  onChange={(e) => setGamId(e.target.value)}
                  placeholder="Ex: 123456789"
                  className="border border-gray-300 p-2 rounded w-full focus:ring focus:ring-blue-200"
                />
              </div>

              {!isDomainSet && (
                <div className="mb-4">
                  <label className="text-gray-600 mb-1 block">
                    Insira o Domínio:
                  </label>
                  <input
                    type="text"
                    value={domain}
                    onChange={(e) => setDomain(e.target.value)}
                    placeholder="Ex: seudominio.com"
                    className="border border-gray-300 p-2 rounded w-full focus:ring focus:ring-blue-200"
                  />
                </div>
              )}
            </>
          )}

          {integrationType === "Automática" && (
            <>
              <div className="mb-4">
                <p className="text-sm text-gray-500">
                  A integração automática redirecionará você para a autenticação
                  OAuth do Google Ad Manager.
                </p>
              </div>
            </>
          )}

          <button
            onClick={handleSave}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-200 w-full"
          >
            {integrationType === "Manual"
              ? "Vincular Google Ad Manager"
              : "Iniciar Integração Automática"}
          </button>
        </>
      )}
    </div>
  );
};

export default AddToAdManager;
