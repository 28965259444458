import React, { useState, useEffect } from "react";
import { FaCopy } from "react-icons/fa";
import toast, { Toaster } from "react-hot-toast";

export default function KeywordChecker() {
  const [campaignKeywords, setCampaignKeywords] = useState("");
  const [newGroupKeywords, setNewGroupKeywords] = useState("");
  const [duplicateKeywords, setDuplicateKeywords] = useState([]);
  const [duplicatesCount, setDuplicatesCount] = useState(0);

  const checkDuplicates = () => {
    const campaignList = campaignKeywords
      .split("\n")
      .map((kw) => kw.trim())
      .filter(Boolean);
    const newGroupList = newGroupKeywords
      .split("\n")
      .map((kw) => kw.trim())
      .filter(Boolean);

    const duplicates = newGroupList.filter((kw) => campaignList.includes(kw));
    setDuplicateKeywords(duplicates);
    setDuplicatesCount(duplicates.length);
  };

  const handleRemoveDuplicates = () => {
    const uniqueNewGroupList = newGroupKeywords
      .split("\n")
      .map((kw) => kw.trim())
      .filter(Boolean)
      .filter((kw) => !duplicateKeywords.includes(kw));

    setNewGroupKeywords(uniqueNewGroupList.join("\n"));
    toast.success(`${duplicatesCount} duplicatas removidas`);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Conteúdo copiado!");
  };

  useEffect(() => {
    checkDuplicates();
  }, [campaignKeywords, newGroupKeywords]);

  const countLines = (text) => text.split("\n").filter(Boolean).length;

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-white text-gray-900 p-6">
      <Toaster position="top-center" reverseOrder={false} />
      <h1 className="text-2xl font-bold mb-2 text-blue-600">KeywordChecker</h1>

      {/* Breve explicação sobre o uso da ferramenta */}
      <p className="text-sm text-gray-500 max-w-lg mb-6 text-center">
        Utilize esta ferramenta para verificar duplicatas de palavras-chave
        entre a campanha principal e um novo grupo de anúncios. Insira uma
        palavra-chave por linha e clique em "Remover Duplicatas" para garantir
        que o novo grupo contenha apenas palavras-chave exclusivas.
      </p>

      <div className="w-full max-w-2xl space-y-6">
        {/* Textarea para palavras-chave da campanha principal */}
        <div className="relative">
          <label className="block font-semibold mb-2 text-gray-700">
            Palavras-chave a nível de Campanha
          </label>
          <textarea
            className="w-full h-32 p-3 bg-white text-gray-800 rounded border border-blue-300 focus:outline-none focus:ring focus:ring-blue-200"
            placeholder="Insira cada palavra-chave em uma linha separada"
            value={campaignKeywords}
            onChange={(e) => setCampaignKeywords(e.target.value)}
          />
          {campaignKeywords && (
            <FaCopy
              className="absolute top-2 right-2 text-blue-500 cursor-pointer"
              onClick={() => copyToClipboard(campaignKeywords)}
            />
          )}
          <p className="text-sm text-gray-500 mt-1">
            Linhas: {countLines(campaignKeywords)}
          </p>
        </div>

        {/* Textarea para novas palavras-chave do grupo de anúncio */}
        <div className="relative">
          <label className="block font-semibold mb-2 text-gray-700">
            Novas Palavras-chave do Grupo de Anúncio
          </label>
          <textarea
            className="w-full h-32 p-3 bg-white text-gray-800 rounded border border-blue-300 focus:outline-none focus:ring focus:ring-blue-200"
            placeholder="Insira cada palavra-chave em uma linha separada"
            value={newGroupKeywords}
            onChange={(e) => setNewGroupKeywords(e.target.value)}
          />
          {newGroupKeywords && (
            <FaCopy
              className="absolute top-2 right-2 text-blue-500 cursor-pointer"
              onClick={() => copyToClipboard(newGroupKeywords)}
            />
          )}
          <p className="text-sm text-gray-500 mt-1">
            Linhas: {countLines(newGroupKeywords)}
          </p>
        </div>

        {/* Exibição da contagem de duplicatas ou mensagem "Nenhuma duplicata encontrada" */}
        {duplicatesCount > 0 ? (
          <p className="text-red-500 font-semibold">
            Duplicatas encontradas: {duplicatesCount}
          </p>
        ) : (
          <p className="text-green-500 font-semibold">
            Nenhuma palavra-chave duplicada
          </p>
        )}

        {/* Botão para remover duplicatas */}
        <button
          onClick={handleRemoveDuplicates}
          className="w-full py-2 px-4 bg-blue-500 rounded font-semibold text-white hover:bg-blue-600 transition-all"
        >
          Remover Duplicatas
        </button>

        {/* Exibição das duplicatas encontradas */}
        {duplicatesCount > 0 && (
          <div className="bg-red-50 p-4 rounded border border-red-200 mt-4">
            <h2 className="text-lg font-semibold mb-2 text-red-600">
              Duplicatas:
            </h2>
            <ul className="list-disc list-inside text-red-600">
              {duplicateKeywords.map((kw, index) => (
                <li key={index}>{kw}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
