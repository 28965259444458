// src/components/ProjectConfigurations.js
import React, { useState, useEffect } from "react";
import { FaCog, FaCheckCircle } from "react-icons/fa";
import toast from "react-hot-toast";
import { addOrUpdateProjectConfig } from "../utils/api";

const ProjectConfigurations = ({
  projectId,
  initialConfigurations,
  initialRoiTargets,
}) => {
  const [configurations, setConfigurations] = useState(initialConfigurations);
  const [roiTargets, setRoiTargets] = useState(initialRoiTargets);
  const [isConfigVisible, setIsConfigVisible] = useState(false);
  const [roiAboveThreshold, setRoiAboveThreshold] = useState("");
  const [roiBelowThreshold, setRoiBelowThreshold] = useState("");

  useEffect(() => {
    calculateRoiThresholds(
      roiTargets.roiGoal,
      roiTargets.roiAbove,
      roiTargets.roiBelow
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roiTargets.roiGoal, roiTargets.roiAbove, roiTargets.roiBelow]);

  const handleConfigBlur = async (key, value) => {
    if (!value.trim()) {
      toast.error("Por favor, preencha este campo.");
      return;
    }

    setConfigurations((prevState) => ({
      ...prevState,
      [key]: value,
    }));

    toast.loading("Salvando configurações...");

    try {
      await addOrUpdateProjectConfig(projectId, key, value);
      toast.dismiss();
      toast.success(`${key} atualizado com sucesso.`);
    } catch (error) {
      toast.dismiss();
      toast.error("Erro ao atualizar configuração: " + error.message);
    }
  };

  const handleRoiBlur = async (key, value) => {
    if (!value.trim()) {
      toast.error("Por favor, preencha este campo.");
      return;
    }

    setRoiTargets((prevState) => ({
      ...prevState,
      [key]: value,
    }));

    toast.loading("Salvando ajustes de ROI...");

    try {
      await addOrUpdateProjectConfig(projectId, key, value);
      toast.dismiss();
      toast.success(`${key} atualizado com sucesso.`);
    } catch (error) {
      toast.dismiss();
      toast.error("Erro ao atualizar configuração: " + error.message);
    }
  };

  const calculateRoiThresholds = (goal, above, below) => {
    const roiGoal = parseFloat(goal);
    const roiAbove = parseFloat(above);
    const roiBelow = parseFloat(below);

    if (!isNaN(roiGoal) && !isNaN(roiAbove)) {
      setRoiAboveThreshold((roiGoal * (1 + roiAbove / 100)).toFixed(2));
    } else {
      setRoiAboveThreshold("");
    }

    if (!isNaN(roiGoal) && !isNaN(roiBelow)) {
      setRoiBelowThreshold((roiGoal * (1 - roiBelow / 100)).toFixed(2));
    } else {
      setRoiBelowThreshold("");
    }
  };

  const toggleConfigVisibility = () => {
    setIsConfigVisible(!isConfigVisible);
  };

  return (
    <>
      <button
        onClick={toggleConfigVisibility}
        className="mt-8 text-blue-500 hover:underline text-sm flex items-center"
      >
        <FaCog className="mr-1" />
        {isConfigVisible ? "Ocultar Configurações" : "Mostrar Configurações"}
      </button>
      {isConfigVisible && (
        <div className="mt-4">
          <div className="grid grid-cols-1 gap-6">
            <div className="flex flex-col">
              <label className="text-gray-600 mb-1 text-sm">
                RevShare (Porcentagem de Receita Compartilhada):
              </label>
              <input
                type="text"
                value={configurations.RevShare}
                onChange={(e) =>
                  setConfigurations({
                    ...configurations,
                    RevShare: e.target.value,
                  })
                }
                onBlur={(e) => handleConfigBlur("RevShare", e.target.value)}
                className="border border-gray-300 p-2 rounded-lg w-full focus:ring focus:ring-blue-300 text-sm"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-600 mb-1 text-sm">
                Tax (Taxa em Porcentagem):
              </label>
              <input
                type="text"
                value={configurations.Tax}
                onChange={(e) =>
                  setConfigurations({
                    ...configurations,
                    Tax: e.target.value,
                  })
                }
                onBlur={(e) => handleConfigBlur("Tax", e.target.value)}
                className="border border-gray-300 p-2 rounded-lg w-full focus:ring focus:ring-blue-300 text-sm"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-600 mb-1 text-sm">
                Dollar Depreciation (Depreciação do Dólar em Centavos):
              </label>
              <input
                type="text"
                value={configurations.DollarDepreciation}
                onChange={(e) =>
                  setConfigurations({
                    ...configurations,
                    DollarDepreciation: e.target.value,
                  })
                }
                onBlur={(e) =>
                  handleConfigBlur("DollarDepreciation", e.target.value)
                }
                className="border border-gray-300 p-2 rounded-lg w-full focus:ring focus:ring-blue-300 text-sm"
              />
            </div>
          </div>

          {/* ROI Configuration */}
          <div className="mt-8">
            <h2 className="text-lg font-semibold mb-4">
              <FaCheckCircle className="mr-1" />
              Meta de ROI (Retorno sobre Investimento)
            </h2>
            <div className="grid grid-cols-1 gap-6">
              <div className="flex flex-col">
                <label className="text-gray-600 mb-1 text-sm">
                  Meta de ROI (%):
                </label>
                <input
                  type="text"
                  value={roiTargets.roiGoal}
                  onChange={(e) =>
                    setRoiTargets({
                      ...roiTargets,
                      roiGoal: e.target.value,
                    })
                  }
                  onBlur={(e) => handleRoiBlur("roiGoal", e.target.value)}
                  className="border border-gray-300 p-2 rounded-lg w-full focus:ring focus:ring-blue-300 text-sm"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-gray-600 mb-1 text-sm">
                  Quanto (%) acima da meta consideraremos ROI:
                </label>
                <input
                  type="text"
                  value={roiTargets.roiAbove}
                  onChange={(e) =>
                    setRoiTargets({
                      ...roiTargets,
                      roiAbove: e.target.value,
                    })
                  }
                  onBlur={(e) => handleRoiBlur("roiAbove", e.target.value)}
                  className="border border-gray-300 p-2 rounded-lg w-full focus:ring focus:ring-blue-300 text-sm"
                />
                {roiAboveThreshold && (
                  <p className="text-gray-500 text-sm mt-1">
                    ROI acima da meta: {roiAboveThreshold}%
                  </p>
                )}
              </div>
              <div className="flex flex-col">
                <label className="text-gray-600 mb-1 text-sm">
                  Quanto (%) abaixo da meta consideraremos ROI:
                </label>
                <input
                  type="text"
                  value={roiTargets.roiBelow}
                  onChange={(e) =>
                    setRoiTargets({
                      ...roiTargets,
                      roiBelow: e.target.value,
                    })
                  }
                  onBlur={(e) => handleRoiBlur("roiBelow", e.target.value)}
                  className="border border-gray-300 p-2 rounded-lg w-full focus:ring focus:ring-blue-300 text-sm"
                />
                {roiBelowThreshold && (
                  <p className="text-gray-500 text-sm mt-1">
                    ROI abaixo da meta: {roiBelowThreshold}%
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectConfigurations;
