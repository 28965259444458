// Navbar.jsx
import React from "react";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    navigate("/login");
  };

  return (
    <nav className="bg-white p-4 shadow-sm">
      <div className="flex justify-between items-center">
        <div className="text-lg font-semibold text-gray-800">Dashboard</div>
        <div>
          <button
            onClick={handleLogout}
            className="text-gray-600 hover:text-gray-800 transition-colors"
          >
            Logout
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
