// Sidebar.jsx
import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { CreditsContext } from "../contexts/CreditsContext";
import { FaPlus, FaAngleLeft, FaAngleRight, FaBars } from "react-icons/fa";
import GeneratePix from "./GeneratePix";
import MenuItems from "./MenuItems"; // Import the MenuItems component
import AssessmentIcon from "@mui/icons-material/Assessment";

const Sidebar = () => {
  const { credits } = useContext(CreditsContext);
  const [username, setUsername] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [hasGadsIntegration, setHasGadsIntegration] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [isMinimized, setIsMinimized] = useState(false);
  const [isPixModalOpen, setIsPixModalOpen] = useState(false); // State for PIX modal
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State for mobile menu
  const didMountRef = useRef(false);
  const location = useLocation();

  useEffect(() => {
    if (didMountRef.current) return;
    didMountRef.current = true;

    const token = localStorage.getItem("token");
    if (token) {
      const payload = JSON.parse(atob(token.split(".")[1]));
      setUsername(payload.username);
      setIsAdmin(payload.scopes && payload.scopes.includes("admin"));
      setHasGadsIntegration(
        payload.scopes &&
          (payload.scopes.includes("gads-integration") ||
            payload.scopes.includes("projects"))
      );
    }

    if (location.pathname.includes("/campaigns")) {
      setActiveMenu("campaigns");
    } else if (location.pathname.includes("/tools")) {
      setActiveMenu("contentCreation");
    } else if (location.pathname.includes("/google-ads-api")) {
      setActiveMenu("googleAdsAPI");
    } else if (location.pathname.includes("/admin")) {
      setActiveMenu("admin");
    }
  }, [location.pathname]);

  const toggleMenu = (menu) => {
    setActiveMenu((prevMenu) => (prevMenu === menu ? null : menu));
  };

  const toggleSidebar = () => {
    setIsMinimized((prev) => !prev);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  return (
    <>
      {/* Navbar for mobile devices */}
      <div className="md:hidden bg-gray-900 text-white flex items-center justify-between p-4 fixed w-full z-10">
        <div className="text-lg font-bold">KaizenADS</div>
        <button onClick={toggleMobileMenu}>
          <FaBars className="text-white text-xl" />
        </button>
      </div>

      {/* Mobile menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-gray-900 text-white fixed top-14 left-0 w-full h-full z-10 overflow-y-auto">
          <div className="p-4 border-b border-gray-700">
            <div className="text-xs text-gray-400">Usuário: {username}</div>
            <div className="text-xs text-gray-400">Créditos: {credits}</div>
            <button
              onClick={() => {
                setIsPixModalOpen(true);
                setIsMobileMenuOpen(false);
              }}
              className="mt-3 flex items-center justify-center bg-white text-black text-sm py-2 px-4 rounded-lg shadow hover:bg-gray-100 transition duration-200"
            >
              <FaPlus className="mr-2" />
              Adicionar mais créditos
            </button>
          </div>
          {/* Use MenuItems here */}
          <MenuItems
            activeMenu={activeMenu}
            toggleMenu={toggleMenu}
            isMinimized={false} // In mobile menu, not minimized
            isAdmin={isAdmin}
            hasGadsIntegration={hasGadsIntegration}
          />
          <div className="p-4 text-xs text-gray-400 text-center border-t border-gray-700">
            As funções de "Criação de conteúdo" e "Google Ads API" estão em
            testes internos por hora.
          </div>
          <div className="p-4 border-t border-gray-700 text-center text-xs">
            <Link
              to="/logout"
              className="block text-red-500 hover:text-red-600 transition-colors duration-200"
            >
              Logout
            </Link>
          </div>
        </div>
      )}

      {/* Sidebar for larger devices */}
      <div
        className={`bg-gray-900 text-white ${
          isMinimized ? "w-20" : "w-64"
        } min-h-screen text-sm transition-all duration-300 hidden md:flex md:flex-col min-w-64`}
      >
        <div className="p-4 border-b border-gray-700 flex items-center justify-between">
          {!isMinimized && (
            <div className="text-lg font-bold text-center text-gray-200">
              KaizenADS
            </div>
          )}
          <button
            onClick={toggleSidebar}
            className="text-gray-400 hover:text-gray-200 transition-colors duration-200"
          >
            {isMinimized ? <FaAngleRight /> : <FaAngleLeft />}
          </button>
        </div>
        <div className="p-4 border-b border-gray-700">
          {!isMinimized && (
            <>
              <div className="text-xs text-gray-400">Usuário: {username}</div>
              <div className="text-xs text-gray-400">Créditos: {credits}</div>
              <button
                onClick={() => setIsPixModalOpen(true)}
                className="mt-3 flex items-center justify-center bg-white text-black text-sm py-2 px-4 rounded-lg shadow hover:bg-gray-100 transition duration-200"
              >
                <FaPlus className="mr-2" />
                Adicionar mais créditos
              </button>
            </>
          )}
        </div>
        {/* Use MenuItems here */}
        <MenuItems
          activeMenu={activeMenu}
          toggleMenu={toggleMenu}
          isMinimized={isMinimized}
          isAdmin={isAdmin}
          hasGadsIntegration={hasGadsIntegration}
        />
        {!isMinimized && (
          <>
            <div className="p-4 text-xs text-gray-400 text-center border-t border-gray-700">
              As funções de "Criação de conteúdo" e "Google Ads API" estão em
              testes internos por hora.
            </div>
            <div className="p-4 border-t border-gray-700 text-center text-xs">
              <Link
                to="/logout"
                className="block text-red-500 hover:text-red-600 transition-colors duration-200"
              >
                Logout
              </Link>
            </div>
          </>
        )}
      </div>
      {/* PIX Modal */}
      {isPixModalOpen && (
        <GeneratePix isOpen={isPixModalOpen} setIsOpen={setIsPixModalOpen} />
      )}
    </>
  );
};

export default Sidebar;
