import React, { useState, useEffect } from "react";
import {
  FaClipboard,
  FaUser,
  FaEnvelope,
  FaCoins,
  FaCalendarAlt,
  FaClock,
  FaCheckCircle,
  FaExclamationCircle,
  FaTimes,
  FaFilter,
  FaSearch,
} from "react-icons/fa";
import {
  getUsers,
  getUserById,
  addUserScope,
  removeUserScope,
  deleteUser,
  banUser,
  addCredits,
  decrementCredits,
} from "../utils/api";
import { confirmAlert } from "react-confirm-alert";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const StatsCard = ({ title, value, icon: Icon }) => (
  <div className="bg-white rounded-lg shadow-sm p-4">
    <div className="flex items-center gap-2 text-gray-500 mb-2">
      <Icon className="text-gray-400" size={16} />
      <p className="text-sm">{title}</p>
    </div>
    <p className="text-xl font-medium">{value}</p>
  </div>
);

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [scopes, setScopes] = useState("");
  const [credits, setCredits] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalUsers: 0,
    activeUsers: 0,
    bannedUsers: 0,
    totalCredits: 0,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const payload = JSON.parse(atob(token.split(".")[1]));
      if (!payload.scopes || !payload.scopes.includes("admin")) {
        navigate("/denied");
        return;
      }
    }

    const fetchUsers = async () => {
      try {
        const response = await getUsers();
        if (response.success) {
          setUsers(response.data);
          // Calcula estatísticas
          const activeUsers = response.data.filter(
            (user) => !isUserBanned(user)
          );
          const bannedUsers = response.data.filter((user) =>
            isUserBanned(user)
          );
          const totalCredits = response.data.reduce(
            (sum, user) => sum + user.credits,
            0
          );

          setStats({
            totalUsers: response.data.length,
            activeUsers: activeUsers.length,
            bannedUsers: bannedUsers.length,
            totalCredits,
          });
        }
      } catch (error) {
        console.error("Erro ao carregar usuários:", error);
        toast.error("Erro ao carregar usuários");
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [navigate]);

  const formatDate = (dateString) => {
    return dayjs(dateString).format("DD/MM/YYYY HH:mm");
  };

  const handleDeleteUser = (id) => {
    confirmAlert({
      title: "Confirmar exclusão",
      message: "Tem certeza que deseja deletar este usuário?",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await deleteUser(id);
              setUsers(users.filter((user) => user.id !== id));
              toast.success("Usuário deletado com sucesso!");
            } catch (err) {
              toast.error("Erro ao deletar usuário.");
            }
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleViewUser = async (id) => {
    try {
      const response = await getUserById(id);
      setSelectedUser(response.data);
      setModalVisible(true);
    } catch (err) {
      toast.error("Erro ao obter detalhes do usuário.");
    }
  };

  const handleAddScope = async (id) => {
    try {
      const response = await addUserScope(id, [scopes]);
      setSelectedUser(response.data);
      setScopes("");
      toast.success("Escopo adicionado com sucesso!");
    } catch (err) {
      toast.error("Erro ao adicionar escopo ao usuário.");
    }
  };

  const handleRemoveScope = async (id) => {
    try {
      const response = await removeUserScope(id, [scopes]);
      setSelectedUser(response.data);
      setScopes("");
      toast.success("Escopo removido com sucesso!");
    } catch (err) {
      toast.error("Erro ao remover escopo do usuário.");
    }
  };

  const handleBanUser = async (id) => {
    confirmAlert({
      title: "Confirmar banimento",
      message: "Tem certeza que deseja banir este usuário?",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await banUser(id);
              setSelectedUser(null);
              setModalVisible(false);
              toast.success("Usuário banido com sucesso!");
            } catch (err) {
              toast.error("Erro ao banir usuário.");
            }
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleAddCredits = async (id, amount) => {
    try {
      const response = await addCredits(id, amount);
      setSelectedUser(response.data);
      toast.success("Créditos adicionados com sucesso!");
    } catch (err) {
      toast.error("Erro ao adicionar créditos ao usuário.");
    }
  };

  const handleDecrementCredits = async (id, amount) => {
    try {
      const response = await decrementCredits(id, amount);
      setSelectedUser(response.data);
      toast.success("Créditos decrementados com sucesso!");
    } catch (err) {
      toast.error("Erro ao decrementar créditos do usuário.");
    }
  };

  const isUserBanned = (user) => {
    return !user.scopes.includes("login");
  };

  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-xl font-medium">Gerenciamento de Usuários</h1>
      </div>

      {/* Stats Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        <StatsCard
          title="Total de Usuários"
          value={stats.totalUsers}
          icon={FaUser}
        />
        <StatsCard
          title="Usuários Ativos"
          value={stats.activeUsers}
          icon={FaCheckCircle}
        />
        <StatsCard
          title="Usuários Banidos"
          value={stats.bannedUsers}
          icon={FaExclamationCircle}
        />
        <StatsCard
          title="Total de Créditos"
          value={stats.totalCredits}
          icon={FaCoins}
        />
      </div>

      {/* Search and Filters */}
      <div className="bg-white rounded-lg shadow-sm p-4 mb-6">
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex-1 relative">
            <FaSearch className="absolute left-3 top-3 text-gray-400" />
            <input
              type="text"
              placeholder="Buscar por nome, email ou username..."
              className="w-full pl-10 pr-4 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Users List */}
      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Usuário
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Créditos
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell">
                  Data de Registro
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:table-cell">
                  Último Acesso
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ações
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredUsers.map((user) => (
                <tr key={user.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4">
                    <div className="flex flex-col">
                      <span className="text-sm font-medium text-gray-900">
                        {user.name}
                      </span>
                      <span className="text-sm text-gray-500">
                        {user.email}
                      </span>
                      <span className="text-xs text-gray-400">
                        @{user.username}
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <span className="text-sm text-gray-900">
                      {user.credits}
                    </span>
                  </td>
                  <td className="px-6 py-4 hidden md:table-cell">
                    <span className="text-sm text-gray-500">
                      {formatDate(user.createdAt)}
                    </span>
                  </td>
                  <td className="px-6 py-4 hidden lg:table-cell">
                    <span className="text-sm text-gray-500">
                      {formatDate(user.updatedAt)}
                    </span>
                  </td>
                  <td className="px-6 py-4">
                    <span
                      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                        !isUserBanned(user)
                          ? "bg-green-100 text-green-800"
                          : "bg-red-100 text-red-800"
                      }`}
                    >
                      {!isUserBanned(user) ? "Ativo" : "Banido"}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-right space-x-2">
                    <button
                      onClick={() => handleViewUser(user.id)}
                      className="text-blue-600 hover:text-blue-900"
                    >
                      Detalhes
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal com design melhorado */}
      {modalVisible && selectedUser && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
            {/* Header do Modal */}
            <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center sticky top-0 bg-white">
              <h2 className="text-xl font-semibold text-gray-800">
                Detalhes do Usuário
              </h2>
              <button
                onClick={() => setModalVisible(false)}
                className="text-gray-400 hover:text-gray-600 transition-colors"
              >
                <FaTimes size={20} />
              </button>
            </div>

            {/* Conteúdo do Modal */}
            <div className="p-6">
              {/* Grid de informações básicas */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                <div className="space-y-2">
                  <div className="flex items-center gap-2 text-gray-600">
                    <FaUser className="text-gray-400" />
                    <span className="text-sm font-medium">Nome:</span>
                    <span className="text-sm">{selectedUser.name}</span>
                  </div>
                  <div className="flex items-center gap-2 text-gray-600">
                    <FaEnvelope className="text-gray-400" />
                    <span className="text-sm font-medium">Email:</span>
                    <span className="text-sm">{selectedUser.email}</span>
                  </div>
                  <div className="flex items-center gap-2 text-gray-600">
                    <FaUser className="text-gray-400" />
                    <span className="text-sm font-medium">Username:</span>
                    <span className="text-sm">@{selectedUser.username}</span>
                  </div>
                </div>
                <div className="space-y-2">
                  <div className="flex items-center gap-2 text-gray-600">
                    <FaCoins className="text-gray-400" />
                    <span className="text-sm font-medium">Créditos:</span>
                    <span className="text-sm">{selectedUser.credits}</span>
                  </div>
                  <div className="flex items-center gap-2 text-gray-600">
                    <FaCalendarAlt className="text-gray-400" />
                    <span className="text-sm font-medium">Inscrito em:</span>
                    <span className="text-sm">
                      {formatDate(selectedUser.createdAt)}
                    </span>
                  </div>
                  <div className="flex items-center gap-2 text-gray-600">
                    <FaClock className="text-gray-400" />
                    <span className="text-sm font-medium">Último acesso:</span>
                    <span className="text-sm">
                      {formatDate(selectedUser.updatedAt)}
                    </span>
                  </div>
                </div>
              </div>

              {/* Seção de Escopos */}
              <div className="mb-6">
                <h3 className="text-sm font-semibold text-gray-700 mb-2">
                  Escopos Ativos
                </h3>
                <div className="flex flex-wrap gap-2">
                  {selectedUser.scopes.map((scope, index) => (
                    <span
                      key={index}
                      className="px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-xs"
                    >
                      {scope}
                    </span>
                  ))}
                </div>
              </div>

              {/* Seção de Gerenciamento */}
              <div className="space-y-4">
                {/* Gerenciar Escopos */}
                <div className="bg-gray-50 rounded-lg p-4">
                  <h3 className="text-sm font-semibold text-gray-700 mb-3">
                    Gerenciar Escopos
                  </h3>
                  <div className="flex gap-2">
                    <input
                      type="text"
                      value={scopes}
                      onChange={(e) => setScopes(e.target.value)}
                      placeholder="Nome do escopo"
                      className="flex-1 px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    <button
                      onClick={() => handleAddScope(selectedUser.id)}
                      className="px-4 py-2 bg-blue-600 text-white rounded-lg text-sm hover:bg-blue-700 transition-colors"
                    >
                      Adicionar
                    </button>
                    <button
                      onClick={() => handleRemoveScope(selectedUser.id)}
                      className="px-4 py-2 bg-red-600 text-white rounded-lg text-sm hover:bg-red-700 transition-colors"
                    >
                      Remover
                    </button>
                  </div>
                </div>

                {/* Gerenciar Créditos */}
                <div className="bg-gray-50 rounded-lg p-4">
                  <h3 className="text-sm font-semibold text-gray-700 mb-3">
                    Gerenciar Créditos
                  </h3>
                  <div className="flex gap-2">
                    <input
                      type="number"
                      value={credits}
                      onChange={(e) => setCredits(e.target.value)}
                      placeholder="Quantidade"
                      className="flex-1 px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    <button
                      onClick={() => handleAddCredits(selectedUser.id, credits)}
                      className="px-4 py-2 bg-green-600 text-white rounded-lg text-sm hover:bg-green-700 transition-colors"
                    >
                      Adicionar
                    </button>
                    <button
                      onClick={() =>
                        handleDecrementCredits(selectedUser.id, credits)
                      }
                      className="px-4 py-2 bg-yellow-600 text-white rounded-lg text-sm hover:bg-yellow-700 transition-colors"
                    >
                      Remover
                    </button>
                  </div>
                </div>

                {/* Ações de Usuário */}
                <div className="flex gap-2 pt-4">
                  <button
                    onClick={() => handleBanUser(selectedUser.id)}
                    className="flex-1 px-4 py-2 bg-red-600 text-white rounded-lg text-sm hover:bg-red-700 transition-colors"
                  >
                    {isUserBanned(selectedUser)
                      ? "Desbanir Usuário"
                      : "Banir Usuário"}
                  </button>
                  <button
                    onClick={() => handleDeleteUser(selectedUser.id)}
                    className="flex-1 px-4 py-2 bg-gray-600 text-white rounded-lg text-sm hover:bg-gray-700 transition-colors"
                  >
                    Deletar Usuário
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Toaster position="top-right" />
    </div>
  );
};

export default UsersPage;
