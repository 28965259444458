// src/components/ProjectDetails.js
import React from "react";
import { FaCog, FaInfoCircle } from "react-icons/fa";

const ProjectDetails = ({ project }) => {
  return (
    <div>
      <h1 className="text-2xl font-bold mb-6 text-gray-800 flex items-center">
        <FaCog className="mr-2" />
        Configuração de Arbitragem: Projeto {project.projectType}
      </h1>
      <p className="text-sm text-gray-500 mb-8 flex items-center">
        <FaInfoCircle className="mr-2" />
        ID do Projeto: {project.id}
      </p>
    </div>
  );
};

export default ProjectDetails;
