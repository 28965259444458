// src/components/TrafficManagers.js
import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import toast from "react-hot-toast";
import {
  addOrUpdateManagerInProject,
  removeManagerFromProject,
} from "../utils/api";

const TrafficManagers = ({ projectId, managers, setManagers }) => {
  const [newManager, setNewManager] = useState("");

  const handleAddManager = async () => {
    if (!newManager.trim()) {
      toast.error("Por favor, preencha o ID ou Username do Gestor de Tráfego.");
      return;
    }

    toast.loading("Adicionando gestor de tráfego...");
    try {
      const result = await addOrUpdateManagerInProject(projectId, newManager);
      if (result && result.error) {
        throw new Error(result.error.error);
      }
      setManagers((prev) => [
        ...prev,
        { id: result.id, name: result.name, username: result.username },
      ]);
      setNewManager("");
      toast.dismiss();
      toast.success("Gestor de tráfego adicionado com sucesso!");
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    }
  };

  const handleRemoveManager = async (managerId) => {
    toast.loading("Removendo gestor de tráfego...");
    try {
      await removeManagerFromProject(projectId, managerId);
      setManagers((prev) => prev.filter((manager) => manager.id !== managerId));
      toast.dismiss();
      toast.success("Gestor de tráfego removido com sucesso!");
    } catch (error) {
      toast.dismiss();
      toast.error("Erro ao remover o gestor de tráfego: " + error.message);
    }
  };

  return (
    <div className="mt-8">
      <h3 className="text-lg font-semibold mb-4">Gestores de Tráfego</h3>
      {managers.length > 0 ? (
        <div className="flex flex-col space-y-2">
          {managers.map((manager) => (
            <div
              key={manager.id}
              className="flex justify-between items-center bg-gray-100 p-2 rounded shadow-sm"
            >
              <div className="text-sm">
                <p className="font-medium">{manager.name}</p>
                <p className="text-gray-500">{manager.username}</p>
              </div>
              <button
                onClick={() => handleRemoveManager(manager.id)}
                className="text-red-500 hover:text-red-700"
              >
                <FaTrash />
              </button>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-600">Nenhum gestor de tráfego adicionado.</p>
      )}

      <div className="mt-4 flex items-center">
        <input
          type="text"
          value={newManager}
          onChange={(e) => setNewManager(e.target.value)}
          placeholder="ID ou Username do Gestor de Tráfego"
          className="border border-gray-300 p-2 rounded-lg w-full focus:ring focus:ring-blue-300 text-sm"
        />
        <button
          onClick={handleAddManager}
          className="ml-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          Adicionar
        </button>
      </div>
    </div>
  );
};

export default TrafficManagers;
