// src/components/AddDomain.js
import React, { useState } from "react";
import toast from "react-hot-toast";
import { addOrUpdateDomainInProject } from "../utils/api";
import psl from "psl";

const AddDomain = ({ projectId, existingDomain, onDomainUpdated }) => {
  const [newDomain, setNewDomain] = useState("");
  const [error, setError] = useState("");
  const [isValidDomain, setIsValidDomain] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value.trim();
    setNewDomain(value);

    // Resetar mensagem de erro e estado de validação
    setError("");
    setIsValidDomain(false);

    // Remover protocolo se presente
    const domainWithoutProtocol = value.replace(/^(http:\/\/|https:\/\/)/, "");

    // Verificar caracteres inválidos
    if (/[^a-zA-Z0-9.-]/.test(domainWithoutProtocol)) {
      setError("O domínio contém caracteres inválidos.");
      return;
    }

    // Remover "/" no final, se houver
    const sanitizedDomain = domainWithoutProtocol.replace(/\/$/, "");

    // Analisar o domínio usando psl
    const parsed = psl.parse(sanitizedDomain);

    if (parsed.error || !parsed.domain) {
      setError("Insira um domínio válido. Ex: seudominio.com.br");
      return;
    }

    if (parsed.subdomain) {
      setError(
        "Por favor, insira apenas o domínio principal (sem subdomínios)."
      );
      return;
    }

    // Domínio válido
    setIsValidDomain(true);
  };

  const handleSaveDomain = async () => {
    if (error || !isValidDomain) {
      toast.error("Corrija os erros antes de salvar.");
      return;
    }

    if (!newDomain.trim()) {
      setError("Por favor, insira um domínio válido.");
      return;
    }

    toast.loading("Salvando domínio...");
    try {
      await addOrUpdateDomainInProject(projectId, newDomain);
      onDomainUpdated(newDomain);
      setNewDomain("");
      setError("");
      setIsValidDomain(false);
      toast.dismiss();
      toast.success("Domínio adicionado com sucesso!");
    } catch (error) {
      toast.dismiss();
      toast.error("Erro ao adicionar o domínio: " + error.message);
    }
  };

  if (existingDomain) {
    return <p className="text-gray-700">{existingDomain}</p>;
  }

  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        <input
          type="text"
          value={newDomain}
          onChange={handleInputChange}
          placeholder="example.com.br"
          className={`border p-2 rounded focus:outline-none focus:ring-1 text-sm flex-grow ${
            error
              ? "border-red-500 ring-red-500"
              : isValidDomain
              ? "border-green-500 ring-green-500"
              : "border-gray-300 focus:ring-blue-500"
          }`}
        />
        <button
          onClick={handleSaveDomain}
          className="ml-2 text-blue-500 hover:text-blue-600 focus:outline-none"
        >
          Adicionar
        </button>
      </div>
      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
    </div>
  );
};

export default AddDomain;
