import React from "react";

const CpcCampaignDataCell = ({ campaign, config, type = "max" }) => {
  try {
    // Verifica se o objeto 'campaign' e 'config' existem e são válidos
    if (
      !campaign ||
      !config ||
      typeof campaign !== "object" ||
      typeof config !== "object"
    ) {
      return <span>N/A</span>;
    }

    // Extrai o cpcMax, cpcMin, roi e currentBid da campanha
    const { cpcMax, cpcMin, roiPercentage, currentBid } = campaign || {};

    // Extrai os dados de configuração relevantes
    const roiGoal = parseFloat(config?.config?.roiGoal);
    const roiAbove = parseFloat(config?.config?.roiAbove); // porcentagem acima do roiGoal
    const roiBelow = parseFloat(config?.config?.roiBelow); // porcentagem abaixo do roiGoal

    // Checa se os valores de ROI, CPC e currentBid são válidos
    const validCpcMax = cpcMax !== null && cpcMax !== undefined;
    const validCpcMin = cpcMin !== null && cpcMin !== undefined;
    const validRoi = roiPercentage !== null && roiPercentage !== undefined;
    const validCurrentBid = currentBid !== null && currentBid !== undefined;

    // Determina o CPC que será exibido com base no 'type' prop
    const cpcValue = type === "max" ? cpcMax : cpcMin;
    const validCpc = type === "max" ? validCpcMax : validCpcMin;

    // Define a cor padrão (preto) para CPC
    let textColor = "text-black";

    // Calcular os limites de ROI com base no roiGoal
    const roiAboveLimit = roiGoal * (1 + roiAbove / 100); // Meta + % acima
    const roiBelowLimit = roiGoal * (1 - roiBelow / 100); // Meta - % abaixo

    // Lógica para determinar a cor do CPC com base no ROI, no tipo de CPC (max/min) e no currentBid
    if (validRoi) {
      if (
        roiPercentage > roiAboveLimit &&
        validCpcMax &&
        validCurrentBid &&
        currentBid >= cpcMax &&
        type === "max"
      ) {
        // ROI está acima do limite de roiAbove e cpcMax está limitando (azul)
        textColor = "text-blue-500";
      } else if (
        roiPercentage < roiBelowLimit &&
        validCpcMin &&
        validCurrentBid &&
        currentBid <= cpcMin &&
        type === "min"
      ) {
        // ROI está abaixo do limite de roiBelow e cpcMin está limitando (vermelho)
        textColor = "text-red-500";
      }
    }

    // Renderiza apenas o valor do CPC com a cor apropriada
    return (
      <span className={textColor}>
        {validCpc ? `R$ ${cpcValue.toFixed(2)}` : "N/A"}
      </span>
    );
  } catch (error) {
    // Loga o erro no console e exibe um fallback amigável
    console.error("Erro no componente CpcCampaignDataCell:", error);
    return <span>N/A</span>;
  }
};

export default CpcCampaignDataCell;
