import React, { useState, useEffect } from "react";
import { getWorkersLogs } from "../utils/api";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import {
  FaServer,
  FaMicrochip,
  FaMemory,
  FaExclamationTriangle,
  FaSync,
  FaClock,
  FaChartLine,
} from "react-icons/fa";
import Modal from "../components/Modal";

// Componente para exibir informações do sistema
const SystemOverview = ({ systemInfo }) => {
  if (!systemInfo) return null;

  const {
    memory = {},
    cpu = {},
    load_average = {},
    uptime = {},
    platform = "Desconhecido",
  } = systemInfo;

  // Valores padrão para evitar erros de undefined
  const cpuModel = cpu?.model || "CPU";
  const cpuUsage = cpu?.usage || 0;
  const memoryPercentage = memory?.percentage || 0;
  const memoryTotal = memory?.total || 0;
  const memoryUsed = memory?.used || 0;
  const memoryFree = memory?.free || 0;
  const loadAvg1min = load_average?.["1min"] || 0;
  const loadAvg5min = load_average?.["5min"] || 0;
  const loadAvg15min = load_average?.["15min"] || 0;
  const uptimeFormatted = uptime?.formatted || "0h 0m";

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold flex items-center">
          <FaServer className="mr-2 text-blue-500" />
          Visão Geral do Sistema
          <span className="ml-2 text-sm text-gray-500 font-normal">
            ({platform})
          </span>
        </h2>
        <div className="text-sm text-gray-500">Uptime: {uptimeFormatted}</div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* CPU Info */}
        <div>
          <div className="mb-4">
            <h3 className="text-sm font-semibold text-gray-600 mb-2">
              CPU ({cpuModel})
            </h3>
            <div className="flex items-center justify-between mb-1">
              <span className="text-sm">Utilização</span>
              <span className="text-sm font-medium">
                {cpuUsage.toFixed(1)}%
              </span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div
                className={`h-2 rounded-full transition-all ${
                  cpuUsage > 80
                    ? "bg-red-500"
                    : cpuUsage > 60
                    ? "bg-yellow-500"
                    : "bg-green-500"
                }`}
                style={{ width: `${cpuUsage}%` }}
              />
            </div>
            <div className="mt-2 grid grid-cols-3 gap-2 text-sm">
              <div className="text-center p-2 bg-gray-50 rounded">
                <div className="font-medium">{loadAvg1min}</div>
                <div className="text-xs text-gray-500">1min</div>
              </div>
              <div className="text-center p-2 bg-gray-50 rounded">
                <div className="font-medium">{loadAvg5min}</div>
                <div className="text-xs text-gray-500">5min</div>
              </div>
              <div className="text-center p-2 bg-gray-50 rounded">
                <div className="font-medium">{loadAvg15min}</div>
                <div className="text-xs text-gray-500">15min</div>
              </div>
            </div>
          </div>
        </div>

        {/* Memory Info */}
        <div>
          <div className="mb-4">
            <h3 className="text-sm font-semibold text-gray-600 mb-2">
              Memória
            </h3>
            <div className="flex items-center justify-between mb-1">
              <span className="text-sm">Utilização</span>
              <span className="text-sm font-medium">
                {memoryPercentage.toFixed(1)}%
              </span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div
                className={`h-2 rounded-full transition-all ${
                  memoryPercentage > 80
                    ? "bg-red-500"
                    : memoryPercentage > 60
                    ? "bg-yellow-500"
                    : "bg-green-500"
                }`}
                style={{ width: `${memoryPercentage}%` }}
              />
            </div>
            <div className="mt-2 grid grid-cols-3 gap-2 text-sm">
              <div className="text-center p-2 bg-gray-50 rounded">
                <div className="font-medium">{memoryTotal.toFixed(1)}GB</div>
                <div className="text-xs text-gray-500">Total</div>
              </div>
              <div className="text-center p-2 bg-gray-50 rounded">
                <div className="font-medium">{memoryUsed.toFixed(1)}GB</div>
                <div className="text-xs text-gray-500">Em Uso</div>
              </div>
              <div className="text-center p-2 bg-gray-50 rounded">
                <div className="font-medium">{memoryFree.toFixed(1)}GB</div>
                <div className="text-xs text-gray-500">Livre</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Componente para exibir card de worker
const WorkerCard = ({ worker }) => {
  // Verificações de segurança para evitar erros de undefined
  const cpuUsage = worker?.cpu_usage || 0;
  const memoryRss = worker?.memory?.rss || 0;
  const memoryVirtual = worker?.memory?.heapTotal || 0;
  const memoryHeapUsed = worker?.memory?.heapUsed || 0;
  const memoryLimit = worker?.memory_limit || 1;

  // Calcular a porcentagem de memória baseado no RSS e no limite
  const memoryPercentage = (memoryRss / memoryLimit) * 100;

  return (
    <div className="bg-white rounded-lg shadow-lg p-4">
      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="text-lg font-semibold flex items-center">
            Worker {worker?.workerId}
            {worker?.isCronWorker && (
              <span className="ml-2 text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded">
                CRON
              </span>
            )}
          </h3>
          <p className="text-sm text-gray-500">PID: {worker?.pid}</p>
        </div>
        <div
          className={`px-2 py-1 rounded text-sm ${
            memoryPercentage > 80 || cpuUsage > 80
              ? "bg-red-100 text-red-800"
              : "bg-green-100 text-green-800"
          }`}
        >
          {memoryPercentage > 80 || cpuUsage > 80 ? "Alerta" : "Normal"}
        </div>
      </div>

      {/* CPU Usage */}
      <div className="mb-4">
        <div className="flex justify-between text-sm mb-1">
          <span>CPU</span>
          <span>{cpuUsage.toFixed(1)}%</span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2">
          <div
            className={`h-2 rounded-full transition-all ${
              cpuUsage > 80
                ? "bg-red-500"
                : cpuUsage > 60
                ? "bg-yellow-500"
                : "bg-green-500"
            }`}
            style={{ width: `${cpuUsage}%` }}
          />
        </div>
      </div>

      {/* Memory Usage */}
      <div>
        <div className="flex justify-between text-sm mb-1">
          <span>Memória</span>
          <span>{memoryPercentage.toFixed(1)}%</span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2">
          <div
            className={`h-2 rounded-full transition-all ${
              memoryPercentage > 80
                ? "bg-red-500"
                : memoryPercentage > 60
                ? "bg-yellow-500"
                : "bg-green-500"
            }`}
            style={{ width: `${memoryPercentage}%` }}
          />
        </div>
        <div className="mt-2 grid grid-cols-2 gap-2 text-xs text-gray-500">
          <div>RSS: {memoryRss}MB</div>
          <div>Heap Total: {memoryVirtual}MB</div>
          <div>Heap Usado: {memoryHeapUsed}MB</div>
          <div>Limite: {memoryLimit}MB</div>
        </div>
      </div>
    </div>
  );
};

const FilterBar = ({ filters, setFilters }) => (
  <div className="bg-white rounded-lg shadow-lg p-4 mb-6">
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
      <select
        value={filters.workerType}
        onChange={(e) =>
          setFilters((prev) => ({ ...prev, workerType: e.target.value }))
        }
        className="form-select rounded-lg border-gray-300"
      >
        <option value="all">Todos os Workers</option>
        <option value="cron">Apenas CRON</option>
        <option value="normal">Apenas Normais</option>
      </select>

      <select
        value={filters.timeRange}
        onChange={(e) =>
          setFilters((prev) => ({ ...prev, timeRange: e.target.value }))
        }
        className="form-select rounded-lg border-gray-300"
      >
        <option value="1">Último minuto</option>
        <option value="5">Últimos 5 minutos</option>
        <option value="15">Últimos 15 minutos</option>
        <option value="60">Última hora</option>
      </select>

      <select
        value={filters.alertType}
        onChange={(e) =>
          setFilters((prev) => ({ ...prev, alertType: e.target.value }))
        }
        className="form-select rounded-lg border-gray-300"
      >
        <option value="all">Todos os Status</option>
        <option value="cpu">Alto CPU ({">"}80%)</option>
        <option value="memory">Alta Memória ({">"}80%)</option>
        <option value="error">Erros</option>
      </select>

      <button
        onClick={() =>
          setFilters({
            workerType: "all",
            timeRange: "1",
            alertType: "all",
          })
        }
        className="px-4 py-2 text-sm text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200"
      >
        Limpar Filtros
      </button>
    </div>
  </div>
);

// Componente principal
const WorkersMonitor = () => {
  const [systemInfo, setSystemInfo] = useState(null);
  const [workers, setWorkers] = useState([]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [filters, setFilters] = useState({
    workerType: "all",
    timeRange: "1",
    alertType: "all",
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      // Buscar informações do sistema
      const sysInfoResponse = await getWorkersLogs({
        type: "system_info",
        limit: 1,
      });

      // Configurar parâmetros para busca de workers
      const workerParams = {
        type: "worker_memory",
        minutes: Number(filters.timeRange),
        order: "desc",
      };

      // Adicionar filtro de tipo de worker
      if (filters.workerType !== "all") {
        workerParams.isCronWorker = filters.workerType === "cron";
      }

      // Buscar workers baseado no tipo de alerta
      let workersResponse;
      if (filters.alertType === "cpu") {
        workersResponse = await getWorkersLogs({
          ...workerParams,
          cpu_threshold: 80,
        });
      } else if (filters.alertType === "memory") {
        workersResponse = await getWorkersLogs({
          ...workerParams,
          memory_threshold: 80,
        });
      } else {
        workersResponse = await getWorkersLogs(workerParams);
      }

      // Buscar erros se necessário
      let errorsResponse = { success: true, data: [] };
      if (filters.alertType === "error" || filters.alertType === "all") {
        errorsResponse = await getWorkersLogs({
          type: "worker_error",
          minutes: Number(filters.timeRange),
          order: "desc",
        });
      }

      // Atualizar estados com as respostas, garantindo que os dados existam
      if (sysInfoResponse?.success && sysInfoResponse?.data?.[0]) {
        setSystemInfo(sysInfoResponse.data[0]);
      }

      if (workersResponse?.success && Array.isArray(workersResponse?.data)) {
        // Agrupar workers pelo ID mais recente
        const latestWorkers = workersResponse.data.reduce((acc, curr) => {
          if (
            curr?.workerId && // Garantir que workerId existe
            (!acc[curr.workerId] ||
              dayjs(curr.timestamp).isAfter(
                dayjs(acc[curr.workerId].timestamp)
              ))
          ) {
            acc[curr.workerId] = curr;
          }
          return acc;
        }, {});

        setWorkers(Object.values(latestWorkers));
      }

      if (errorsResponse?.success && Array.isArray(errorsResponse?.data)) {
        setErrors(errorsResponse.data);
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [filters]);

  useEffect(() => {
    const interval = autoRefresh ? setInterval(fetchData, 5000) : null;
    return () => interval && clearInterval(interval);
  }, [autoRefresh, filters]);

  return (
    <div className="container mx-auto px-4 py-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Monitor de Workers</h1>
        <div className="flex items-center space-x-4">
          <button
            onClick={fetchData}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors flex items-center"
            disabled={loading}
          >
            <FaSync className={`mr-2 ${loading ? "animate-spin" : ""}`} />
            Atualizar
          </button>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={autoRefresh}
              onChange={(e) => setAutoRefresh(e.target.checked)}
              className="form-checkbox h-5 w-5 text-blue-500"
            />
            <span>Auto Refresh</span>
          </label>
        </div>
      </div>

      {/* Filtros */}
      <FilterBar filters={filters} setFilters={setFilters} />

      {/* System Overview */}
      {systemInfo && <SystemOverview systemInfo={systemInfo} />}

      {/* Workers Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {workers.map((worker) => (
          <WorkerCard key={worker.workerId} worker={worker} />
        ))}
      </div>

      {/* Error List */}
      {errors.length > 0 && (
        <div className="mt-6 bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-xl font-bold mb-4 flex items-center">
            <FaExclamationTriangle className="mr-2 text-red-500" />
            Erros Recentes
          </h2>
          <div className="space-y-4">
            {errors.map((error, index) => (
              <div
                key={index}
                className="border-l-4 border-red-500 bg-red-50 p-4 rounded"
              >
                <div className="flex justify-between">
                  <span className="font-medium">Worker {error.workerId}</span>
                  <span className="text-sm text-gray-500">
                    {dayjs(error.timestamp).format("DD/MM HH:mm:ss")}
                  </span>
                </div>
                <p className="text-sm mt-1">{error.error.message}</p>
                {error.error.code && (
                  <p className="text-xs text-gray-600 mt-1">
                    Código: {error.error.code}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkersMonitor;
