// src/components/LabelDisplay.jsx
import React from "react";

/**
 * Componente LabelDisplay
 *
 * Este componente recebe um objeto `project` e um array de strings `labels` (resourceNames).
 * Ele busca cada label correspondente dentro de `project.GadsAccountLabels` e exibe
 * o nome de cada label com a cor de fundo especificada. A descrição de cada label é
 * exibida como um tooltip ao passar o mouse sobre o elemento.
 *
 * @param {Object} props - As propriedades do componente.
 * @param {Object} props.project - O objeto do projeto contendo os labels.
 * @param {string[]} props.labels - Array de resourceNames dos labels a serem exibidos.
 * @returns {JSX.Element|null} - O elemento JSX para exibir os labels ou null se não encontrado.
 */
const LabelDisplay = ({ project, labels }) => {
  // Verifica se o projeto e os labels estão disponíveis
  if (
    !project ||
    !Array.isArray(project.GadsAccountLabels) ||
    !Array.isArray(labels)
  ) {
    return null;
  }

  // Mapeia cada resourceName para o label correspondente
  const renderedLabels = labels.map((resourceName, index) => {
    const foundLabel = project.GadsAccountLabels.find(
      (lbl) => lbl.resourceName === resourceName
    );

    // Se o label não for encontrado, pula para o próximo
    if (!foundLabel) {
      return null;
    }

    // Extrai as informações necessárias do label
    const { name, textLabel } = foundLabel;
    const { description = "", backgroundColor = "#FFFFFF" } = textLabel || {};

    return (
      <span
        key={index}
        className="inline-block px-1 py-0.5 rounded-sm text-white text-xs font-normal truncate mr-1 mb-1"
        style={{ backgroundColor }}
        title={description}
      >
        {name}
      </span>
    );
  });

  // Filtra os labels que foram encontrados
  const validLabels = renderedLabels.filter((label) => label !== null);

  // Se nenhum label válido for encontrado, retorna null
  if (validLabels.length === 0) {
    return null;
  }

  return <div className="flex flex-wrap">{validLabels}</div>;
};

export default LabelDisplay;
