import React from "react";

const DataCell = ({ data, index }) => {
  // Verifica se 'data' é um array e se o índice é válido
  if (!Array.isArray(data) || index < 0 || index >= data.length) {
    return <span>N/A</span>;
  }

  const item = data[index];

  // Verifica se o item existe e é um objeto
  if (!item || typeof item !== "object") {
    return <span>N/A</span>;
  }

  // Extrai os valores, definindo valores padrão caso as propriedades não existam
  const {
    roiRevenue = 0,
    roiPercentage = 0,
    currentBid = 0,
    googleAdsCost = 0,
    adManagerRevenueBRL = 0,
  } = item;

  // Define as classes de cor para 'roiRevenue' e 'roiPercentage'
  const roiRevenueClass =
    roiRevenue > 0
      ? "text-green-500"
      : roiRevenue < 0
      ? "text-red-500"
      : "text-black";

  const roiPercentageClass =
    roiPercentage > 0
      ? "text-green-500"
      : roiPercentage < 0
      ? "text-red-500"
      : "text-black";

  // Formata o texto de saída, aplicando as classes apenas em 'roiRevenue' e 'roiPercentage'
  return (
    <span className="text-black">
      Lucro:<span className={roiRevenueClass}> R$ {roiRevenue} </span>/
      <span className={roiPercentageClass}>
        <strong> ROI: {roiPercentage}% </strong>{" "}
      </span>
      /<span> CPC: {currentBid} </span>/<span> Custo: R$ {googleAdsCost}</span>
      {/* <span>{adManagerRevenueBRL}</span> */}
    </span>
  );
};

export default DataCell;
